//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchFilter} from "@/api/master";
import {fetchCarTransferList} from "@/api/carTransfer"
import {
	fetchCommissionReport,
	fetchReportStep4,
	fetchTransferCostExcelReport,
	fetchTransferCostReport
} from "@/api/report"
import {Message} from "element-ui";
import func from "@/utils/decoded";
import moment from "moment/moment";

const fields = [
	{key: 'contract_id', label: 'เลขที่สัญญา', _style: 'min-width:100px'},
	{key: 'name_customer', label: 'ชื่อ-สกุล', _style: 'min-width:150px;'},
	{key: 'start_date_contract', label: 'วันที่เริ่มทำสัญญา', _style: 'min-width:100px;'},
	{key: 'balance', label: 'ยอดเงินคงเหลือ', _style: 'min-width:100px; text-align: right;'},
	{key: 'status_id', label: 'สถานะ', _style: 'min-width:100px;'},
	{key: 'appointment_day', label: 'กำลังจะถึงวันนัดหมาย', _style: 'min-width:50px; text-align: center;'},
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
	name: 'TransferCar',
	data() {
		return {
			options: [],
			show: true,
			isCollapsed: true,
			items: [],
			fields,
			filterParams: {
				stepId: 4
			},
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0,
				isBalance: false
			},
			report: {
				contractId: ''
			},
			privilege: { read: false, write: false, report: false }
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},

	async mounted() {
		this.$store.state.loading = true
		try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
			await new Promise((resolve, reject) => {
				fetchFilter(this.filterParams).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data

						console.log('filter : ', this.options);
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await this.getCarTransferList()
		} finally {
			this.$store.state.loading = false
		}

		const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
	},

	methods: {
		async getCarTransferList() {
			console.log('searchParams : ', this.searchParams);
			await fetchCarTransferList(this.searchParams).then(res => {
				this.items = []
				const check = res.header;
				const data = res.body;
				if (check.error === 'N') {
					for (let item of data) {
						if (item.status_id === 4) {
							item.status_label = 'นัดหมายลูกค้า[เรียบร้อย]'
						} else if (item.status_id === 1) {
							item.status_label = 'การโอนรถ[ไม่เรียบร้อย]'
						} else if (item.status_id === 2) {
							item.status_label = 'การโอนรถ[เรียบร้อย]'
						}
						this.items = data;
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		getBadge(status) {
			switch (status) {
				case 2:
					return 'success'
				case 4:
					return 'warning'
				case 1:
					return 'danger'
				default:
					return 'primary'
			}
		},
		rowClickDetail(item) {
			this.$router.push({path: `/step4/TransferCarDetail/${item.contract_id}`})
		},
		handleContractExport() {
			const loading = this.$loading({
				lock: true,
				text: 'Please wait',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

			fetchReportStep4(this.searchParams).then(response => {
				const fileURL = window.URL.createObjectURL(new Blob([response]))
				const fileName = 'Step4_' + func.generateReportDate() + '.xlsx'
				const link = document.createElement('a')

				link.href = fileURL
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				loading.close()
				if (response.size > 0 && response.type !== 'application/json') {
					link.click()
				} else {
					this.$notify({
						title: 'Error',
						message: 'ไม่สามารถสร้างรายงานการโอนรถ',
						type: 'error',
						duration: 5000
					})
				}
				link.remove()

				// Just to simulate the time of the request
				setTimeout(() => {
					loading.close()
				}, 1.5 * 1000)
			})
		},
		handleTransferCostExport(contractId) {
			this.report.contractId = contractId

			const loading = this.$loading({
				lock: true,
				text: 'Please wait',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

			fetchTransferCostReport(this.report).then(response => {
				const fileURL = window.URL.createObjectURL(new Blob([response]))
				const fileName = 'TransferCost_' + contractId + '_' + func.generateReportDate() + '.pdf'
				const link = document.createElement('a')

				link.href = fileURL
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				loading.close()
				if (response.size > 0) {
					link.click()
				} else {
					this.$notify({
						title: 'Error',
						message: 'ไม่พบข้อมูลค่าใช้จ่ายในการโอนรถ',
						type: 'error',
						duration: 5000
					})
				}
				link.remove()

				// Just to simulate the time of the request
				setTimeout(() => {
					loading.close()
				}, 1.5 * 1000)
			})
		},
		handleCommissionExport(contractId) {
			this.report.contractId = contractId

			const loading = this.$loading({
				lock: true,
				text: 'Please wait',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

			fetchCommissionReport(this.report).then(response => {
				const fileURL = window.URL.createObjectURL(new Blob([response]))
				const fileName = 'Commission_' + contractId + '_' + func.generateReportDate() + '.pdf'
				const link = document.createElement('a')

				link.href = fileURL
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				loading.close()
				if (response.size > 0) {
					link.click()
				} else {
					this.$notify({
						title: 'Error',
						message: 'ไม่มีข้อมูลคอมมิชชั่น',
						type: 'error',
						duration: 5000
					})
				}
				link.remove()

				// Just to simulate the time of the request
				setTimeout(() => {
					loading.close()
				}, 1.5 * 1000)
			})
		},
		handleTransferCostExcelExport(contractId) {
			this.report.contractId = contractId

			const loading = this.$loading({
				lock: true,
				text: 'Please wait',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

			fetchTransferCostExcelReport(this.report).then(response => {
				const fileURL = window.URL.createObjectURL(new Blob([response]))
				const fileName = 'TransferCost_' + contractId + '_' + func.generateReportDate() + '.xlsx'
				const link = document.createElement('a')

				link.href = fileURL
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				loading.close()
				if (response.size > 0) {
					link.click()
				} else {
					this.$notify({
						title: 'Error',
						message: 'ไม่พบข้อมูลค่าใช้จ่ายในการโอนรถ',
						type: 'error',
						duration: 5000
					})
				}
				link.remove()

				// Just to simulate the time of the request
				setTimeout(() => {
					loading.close()
				}, 1.5 * 1000)
			})
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		}
	}
}
